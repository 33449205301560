
.content-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    .header {
        display: flex;
        justify-content: space-between;
        .practice-class {
            display: flex;
            align-items: center;
            .question-class, .module-class {
                display: flex;
                align-items: center;
                margin-right: 30px;
                .text {
                    font-size: 16px;
                    color: #333333;;
                    margin-right: 20px;
                }
            }
        }
        .search-add {
            display: flex;
            align-items: center;
            .select-list-right {
                margin-right: 30px;
            }
            .w94-green-btn {
                i {
                    font-size: 12px;
                    margin-right: 5px;
                }
            }
        }
    }
    .content-table {
        width: 100%;
        margin-top: 20px;
        flex: 1;
    }
}
